<template>
	 <div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 预约管理</el-breadcrumb-item>
		    </el-breadcrumb>
		</div>
		 <div class="container">
			 <div class="handle-box">
			     <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
				 <el-button type="primary" icon="search" @click="getData(1)" style="margin-left:10px;">搜索</el-button>
				 <el-button type="primary"  @click="handlePros">项目</el-button>
				 <el-button type="primary" @click="handleProsCate">科室</el-button>
				 <el-button type="primary" @click="handleConfig">设置</el-button>
				 <el-date-picker
						style="margin-left:20px;"
				        class="mr10"
				        v-model="value2"
				        type="datetimerange"
				        format="yyyy-MM-dd HH:mm:ss"
				        :picker-options="pickerOptions"
				        @change="changeTime"
				        range-separator="至"
				        start-placeholder="开始日期"
				        end-placeholder="结束日期"
				        align="right">
				 </el-date-picker>
				 <export-excel style="margin-left:20px;" :apiUrl="apiUrl" :params="params" :filename="filename" :tHeader="tHeader" :tValue="tValue"></export-excel>
			 </div>
			 <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" label="编号" width="80" align="center" >
				 </el-table-column>
				 <el-table-column prop="nickname" label="昵称" width="100" align="center" >
				  </el-table-column>
				  <el-table-column prop="name" label="名称" width="180" align="center" >
				  </el-table-column>
				  <el-table-column prop="mobile" label="电话" width="120" align="center" >
				  </el-table-column>
				  <el-table-column prop="project" label="项目" width="100" align="center" >
				  </el-table-column>
				  <el-table-column prop="" label="预约时间" width="180" align="center" >
						<template slot-scope='scope'>
							<span>{{scope.row.appointment_time}} {{scope.row.time_period}}</span>
						</template>
				  </el-table-column>
				  <el-table-column prop="remark" label="备注" width="180" align="center" >
				  </el-table-column>
<!-- 				  <el-table-column prop="pre_status_text" label="预约状态" width="80" align="center" >
				  </el-table-column> -->
				  <el-table-column prop="" label="是否已发送订阅消息" width="180" align="center" >
					  <template slot-scope='scope'>
						   <span v-if="scope.row.is_get_scb < 1">否</span>
						   <span v-if="scope.row.is_get_scb == 1">是</span>
					  </template>
				  </el-table-column>
				  <el-table-column prop="create_time" label="提交时间" width="180" align="center" >
				  </el-table-column>
				  <el-table-column label="操作" align="center" width="180">
					  <template slot-scope="scope">
						  <el-button type="text" v-if="scope.row.pre_status == 1" icon="el-icon-edit" @click="handlePreStatus(scope.$index, scope.row, 2)">预约成功</el-button>
						  <el-button type="text" v-if="scope.row.pre_status == 1" icon="el-icon-edit" @click="handlePreStatus(scope.$index, scope.row, 5)">预约失败</el-button>
						  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					  </template>
				  </el-table-column>
			 </el-table>
			 <div class="pagination">
			     <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			     </el-pagination>
			 </div>
		 </div>
		  <el-dialog title="项目列表" v-loading="loading" :visible.sync="dialogProsTable" width="70%">
			   <el-button type="primary"  @click="handleProsEdit(undefined, undefined, 1)">添加</el-button>
			   <el-table :data="prosTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column>
				   <el-table-column prop="pro_name" label="名称" width="180" align="center" ></el-table-column>
				    <el-table-column prop="cate_name" label="科室" width="100" align="center" ></el-table-column>
					<el-table-column prop="add_time" label="添加时间" width="180" align="center" ></el-table-column>
					<el-table-column prop="update_time" label="更新时间" width="180" align="center" ></el-table-column>
					<el-table-column prop="sort" label="排序" width="180" align="center" ></el-table-column>
					<el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.is_show == 1">启用</span>
							<span v-else>禁用</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePros(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			   </el-table>
			   <div class="pagination">
			       <el-pagination background @current-change="handleProsCurrentChange" layout="prev, pager, next" :total="prosSumPage">
			       </el-pagination>
			   </div>
		  </el-dialog>
		  <el-dialog title="科室列表" v-loading="loading" :visible.sync="dialogProsCateTable" width="80%">
			  <el-button type="primary"  @click="handleProsCateEdit(undefined, undefined, 1)">添加</el-button>
			  <el-table :data="prosCateTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="180" align="center" sortable></el-table-column>
				   <el-table-column prop="cate_name" label="名称" width="180" align="center" ></el-table-column>
				    <el-table-column prop="time_peri" label="间隔时间" width="180" align="center" ></el-table-column>
					 <el-table-column prop="choose_pro_max" label="选择最大值" width="180" align="center" ></el-table-column>
				    <el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.is_show == 1">启用</span>
							<span v-else>禁用</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsCateEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteProsCate(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			  </el-table>
		  </el-dialog>
		
			<el-dialog title="添加科室" v-loading="loading" ref="prosCateForm" :visible.sync="dialogAddProsCate" width="30%">
				<el-form ref="prosForm" :model="prosCateForm" label-width="130px">
				    <el-form-item label="科室名称" prop="cate_name">
				        <el-input v-model="prosCateForm.cate_name" style="width:400px" placeholder="请输入名称科室"></el-input>
				    </el-form-item>
					<el-form-item label="选择项目最大值" prop="choose_pro_max">
					    <el-input v-model="prosCateForm.choose_pro_max" style="width:400px" placeholder=""></el-input>
						<span>一次提交限制预约该科室项目最大值</span>
					</el-form-item>
					<el-form-item label="间隔时间" prop="time_peri">
					    <el-input v-model="prosCateForm.time_peri" style="width:400px" placeholder=""></el-input>
						<span>用于列出时间表</span>
					</el-form-item>
					<el-form-item label="状态" prop="is_show">
						 <el-radio v-model="prosCateForm.is_show"  label="0" >禁用</el-radio>
						  <el-radio v-model="prosCateForm.is_show" label="1">启用</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
					    <el-input v-model="prosCateForm.sort" style="width:400px" placeholder="请输入排序"></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="dialogAddProsCate = false">关 闭</el-button>
				    <el-button type="primary" @click="saveProsCate()">确 定</el-button>
				</span>
			</el-dialog>
			
			<el-dialog title="设置邮件提醒" v-loading='loading' :visible.sync="dialogEmail" width="30%">
				<el-form :model="emailData" label-width="130px">
					<el-form-item label="qq邮箱发件人地址" prop="">
					    <el-input v-model="emailData.from_email" style="width:400px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="发件邮箱授权码" prop="">
					    <el-input v-model="emailData.auth_code" style="width:400px" placeholder=""></el-input>
						<span style="color: red;">在qq邮箱账户里获取授权码</span>
					</el-form-item>
					<el-form-item label="接收邮件地址" prop="">
					    <el-input v-model="emailData.to_email" style="width:400px" placeholder=""></el-input>
					</el-form-item>
					
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="dialogEmail = false">关 闭</el-button>
				    <el-button type="primary" @click="saveConfig()">确 定</el-button>
				</span>
			</el-dialog>
			
			<el-dialog title="添加项目" v-loading="loading" ref="prosForm" :visible.sync="dialogAddPros" width="30%">
				<el-form ref="prosForm" :model="prosForm" label-width="130px">
					<el-form-item label="项目名称" prop="pro_name">
					    <el-input v-model="prosForm.pro_name" style="width:400px" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="科室" prop="">
					    <el-select v-model='prosForm.cate' placeholder="请选择科室">
							<el-option
							        v-for="item in prosCateTableData"
							        :key="item.id"
							        :label="item.cate_name"
							        :value="item.id">{{item.title}}
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态" prop="is_show">
						 <el-radio v-model="prosForm.is_show"  label="0" >禁用</el-radio>
						  <el-radio v-model="prosForm.is_show" label="1">启用</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
					    <el-input v-model="prosForm.sort" style="width:400px" placeholder="请输入排序"></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="dialogAddPros = false">关 闭</el-button>
				    <el-button type="primary" @click="savePros()">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 删除提示框 -->
			<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
			    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
			    <span slot="footer" class="dialog-footer">
			        <el-button @click="delVisible = false">取 消</el-button>
			        <el-button type="primary" @click="deleteRow(delMode)">确 定</el-button>
			    </span>
			</el-dialog>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	import  ExportExcel from '.././utils/index';
	export default {
		name: 'basetable',
		components: {
		    quillEditor,
			ExportExcel
		},
		 data() {
			 return{
				 tableData: [],
				 cur_page: 1,  //当前页
				 number: 10,  //每页显示条数
				 sumPage:10,  //总页数
				 pros_cur_page:1,
				 prosSumPage:10, 
				 prosTableData:[],
				 prosCateTableData:[],
				 multipleSelection: [],
				 dialogProsTable:false,
				 dialogProsCateTable:false,
				 dialogAddProsCate:false,
				 dialogAddPros:false,
				 select_word:'',
				 is_search: false,
				 mobile:'',
				 loading:false, //加载中
				 del_list: [],
				 value2: [],
				pickerOptions: {
				    shortcuts: [{
				        text: '最近一周',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近一个月',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近三个月',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近半年',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
				            picker.$emit('pick', [start, end]);
				        }
				    }, {
				        text: '最近一年',
				        onClick(picker) {
				            const end = new Date();
				            const start = new Date();
				            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
				            picker.$emit('pick', [start, end]);
				        }
				    }]
				},
				params: null,
				 prosForm:{
					 id:'',
					 pro_name:'',
					 cate:'',
					 is_show:1,
					 sort:0
				 },
				 prosCateForm:{
					 id:'',
					 cate_name:'',
					 is_show:1,
					 sort:0,
					 choose_pro_max:0,
					 time_peri:0
				 },
				 delMode:'pros',
				 delVisible:false,
				 dialogEmail:false,
				 appointid:'',
				 emailData:{
					 id:'',
					 from_email:'',		//发件邮箱地址
					 auth_code:'',		//授权码
					 to_email:''			//收件邮箱地址 
				 },
				 tHeader:['ID', '微信昵称', '名称', '电话', '项目', '预约时间', '备注', '提交时间'],
				 tValue:['id', 'nickname', 'name', 'mobile', 'project', 'appointment_time','remark', 'create_time'],
				 filename:'预约数据',
				 apiUrl: "ShopAppoint/exportExcel",
			 }
		 },
		 created() {
			 //获取初始数据
			 this.params={
				select_word: this.select_word,
			    startTime: this.value2 ? this.value2[0] : null,
			    endTime: this.value2 ? this.value2[1] : null,
			 };
			 
		     this.getData();
		
		 },
		 computed: {
		     data() {
		         return this.tableData.filter((d) => {
		             let is_del = false;
		             for (let i = 0; i < this.del_list.length; i++) {
		                 if (d.name === this.del_list[i].name) {
		                     is_del = true;
		                     break;
		                 }
		             }
		             return d;
		         })
		     }
		 },
		  methods: {
			  getData(){
				  
				  this.params={
				      select_word: this.select_word,
				      status: this.selectedStatus,
				      cid: this.selectedCid,
				      startTime: this.value2 ? this.value2[0] : null,
				      endTime: this.value2 ? this.value2[1] : null,
				  };
				  
				  var params=this.$qs.stringify({
				      select_word: this.select_word,
				      mobile: this.mobile,
					  pageIndex: this.cur_page,
					  number: this.number,
					  startTime: this.value2 ? this.value2[0] : null,
					  endTime: this.value2 ? this.value2[1] : null,
				  });
				  // console.log(params);
				  this.$api.post('ShopAppoint/getAppointList', params, res => {
				      this.tableData = res.data.list;
				      this.sumPage = res.data.sumPage*10;
				      this.cur_page = res.data.currentPage;
				      console.log(res);
				  }, err => {
				      this.tableData = [];
				      this.sumPage = 1*10;
				      this.cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			  handlePreStatus(index,row,status){		//更新预约状态
				var params = this.$qs.stringify({
					id:row.id,
					pre_status: status
				})
				 this.$api.post('ShopAppoint/updatePreStatus', params, res => {
				     this.getData();
				     this.$message.success(res.msg);
				 }, err => {
				     this.$message.error(err.msg);
				 });
			  },
			  
			  handleDelete(index,row){
				  this.delVisible = true
				  this.appointid = row.id
				  this.delMode = 'appoint'
				  
			  },
			  handlePros(){	//项目
				var params=this.$qs.stringify({
					  pageIndex: this.pros_cur_page,
					  number: this.number,
				});
			  	this.$api.post('ShopAppoint/getProsList', params, res => {
			  	    this.prosTableData = res.data.list;
					this.dialogProsTable = true
					this.prosSumPage = res.data.sumPage*10;
					this.pros_cur_page = res.data.currentPage;
			  	}, err => {
				      this.prosTableData = [];
				      this.prosSumPage = 1*10;
				      thispros_cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			  handleProsCate(){	//科室
				var params = null;
			  	this.$api.post('ShopAppoint/getProsCateList', params, res => {
			  	   this.prosCateTableData = res.data
				   this.dialogProsCateTable = true
			  	});
			  },
			  handleProsEdit(index,row,status){
				  var params=this.$qs.stringify({
				  		is_show:1
				  })
				  this.$api.post('ShopAppoint/getProsCateList', params, res => {
				     this.prosCateTableData = res.data
				  });
				  if(status == 1){	//添加
						this.prosForm={
							id:'',
							pro_name:'',
							cate:'',
							is_show:1,
							sort:0
						}
				  }else{
					  var item = this.prosTableData[index];
					  this.prosForm = item
				  }
				  this.dialogAddPros = true
			  },
			  handleProsCateEdit(index, row, status){
				   if(status  ==  1){
					   this.prosCateForm = {
							  id:'',
							  cate_name:'',
							  is_show:1,
							  sort:0,
							  choose_pro_max:0,
							  time_peri:0
					   }
				   }else{
					  var item = this.prosCateTableData[index];
					  this.prosCateForm ={
						  id:item.id,
						  cate_name:item.cate_name,
						  is_show:item.is_show,
						  sort:item.sort,
						  choose_pro_max:item.choose_pro_max,
						  time_peri:item.time_peri
					  }
					  console.log(this.prosCateForm)
				   }
					this.dialogAddProsCate = true
			  },
			  saveProsCate(){		//保存科室
				   var params=this.$qs.stringify({
					   id:this.prosCateForm.id,
					   cate_name:this.prosCateForm.cate_name,
					   is_show:this.prosCateForm.is_show,
					   sort:this.prosCateForm.sort,
					   choose_pro_max:this.prosCateForm.choose_pro_max,
					   time_peri:this.prosCateForm.time_peri
				   })
				  this.$api.post('ShopAppoint/addProsCate',params,res=>{
					  this.dialogAddProsCate = false
					  this.handleProsCate()
					  this.$message.success(res.msg);
				  },err=>{
					   this.$message.error(err.msg);
				  })
			  },
			  savePros(){		//保存项目
				  var params = this.$qs.stringify({
					  id:this.prosForm.id,
					  pro_name:this.prosForm.pro_name,
					  is_show:this.prosForm.is_show,
					  sort:this.prosForm.sort,
					  cate:this.prosForm.cate
				  })
				  this.$api.post('ShopAppoint/addPros',params,res=>{
					  this.dialogAddPros = false
					  this.handlePros()
					  this.$message.success(res.msg);
				  },err=>{
					   this.$message.error(err.msg);
				  })
			  },
			  handleDeleteProsCate(index,row){	//删除科室提示
				  this.prosCateForm = row;
				  this.delVisible = true;
				  this.delMode = 'pros_cate'
			  },
			  handleDeletePros(index,row){	//删除项目
				  this.prosForm = row
				  this.delVisible = true
				  this.delMode = 'pros'
			  },
			  deleteRow(delmode){		//删除科室/项目
				  var url = ''
				  var id = ''
				  if(delmode == 'pros'){
					 url = 'ShopAppoint/delPros'
					 id = this.prosForm.id
				  }else if(delmode == 'pros_cate'){
					 url = 'ShopAppoint/delProsCate'
					 id = this.prosCateForm.id
				  }else if(delmode == 'appoint'){
					  url  = 'ShopAppoint/delAppoint'
					  id = this.appointid
				  }
				  var params=this.$qs.stringify({
				      id: id
				  });
				  this.$api.post(url, params, res => {
					    if(delmode == 'pros'){
							this.handlePros()
						}else if(delmode == 'pros_cate'){
							this.handleProsCate();
						}else if(delmode == 'appoint'){
							this.getData()
						}
				      this.$message.success(res.msg+res.data+"条数据");
				  }, err => {
				      this.$message.error(err.msg);
				  });
				  this.delVisible = false;
			  },
			  handleConfig(){		//邮箱信息
					this.dialogEmail = true
				    this.$api.get('ShopAppoint/email',null,res=>{
					 this.emailData =  res.data
					 
				  },err => {
				    this.emailData = []
				    this.$message.error(err.msg);
				 });
			  },
			  saveConfig(){
				  var params=this.$qs.stringify({
				      id: this.emailData.id,
					  from_email:this.emailData.from_email,
					  auth_code:this.emailData.auth_code,
					  to_email:this.emailData.to_email
				  });
				  this.$api.post('ShopAppoint/saveConfig', params, res => {
						this.dialogEmail =  false
						this.$message.success(res.msg);
				  }, err => {
				      this.$message.error(err.msg);
				  });
			  },
			  // 分页导航
			  handleCurrentChange(val) {
			      this.cur_page = val;
			      this.getData();
			  },
			  handleProsCurrentChange(val){
				  this.pros_cur_page = val
				   this.handlePros()
			  },
			  handleSelectionChange(val) {
			      this.multipleSelection = val;
			  },
			  //确认时间时发起搜索
			  changeTime(){
			      this.getData();
			  },
			search() {
				this.is_search = true;
				this.getData();
			},
		  }
	}
</script>

<style scoped>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.red{
	    color: #ff0000;
	}
</style>
